import { ZodNumber, z } from 'zod'

export const id = () => z.number().int().gt(0)
export const mainCurrency = (schema: ZodNumber) => precision(schema, 5)
export const fractionalCurrency = (schema: ZodNumber) => precision(schema, 7)

export function precision(schema: ZodNumber, precision: number, message?: string) {
  return schema.refine(
    value => {
      const valueAsString = value.toString()

      if (valueAsString.indexOf('.') === -1) {
        return true
      }

      const valuePrecision = valueAsString.split('.')[1].replace(/0+$/, '')

      return valuePrecision.length <= precision
    },
    message || `Precision cannot be more than ${precision}.`
  )
}


export function time(includeSeconds: boolean = false) {
  return includeSeconds
    ? z.string().time()
    : z.string().regex(/^([0-1][0-9]|2[0-3]):[0-5][0-9]$/, 'Invalid time')
}
