<script setup lang="ts">
import { inject, type Ref } from 'vue'

const headerPlacement = inject<Ref<'inside' | 'outside'>>('card.headerPlacement')
const isOpen = inject<Ref<boolean>>('card.isOpen')

</script>

<template>
  <div :class="[$style['card-content'], $style[`header-${headerPlacement}`], { 'd-none': !isOpen }]">
    <slot />
  </div>
</template>

<style module lang="scss">
$border-radius: .625rem;

.header-outside {
  box-shadow: .125rem .125rem .25rem rgba(0, 0, 0, 0.16);
  border: .1px solid #d1d1d1;
  background: #fff;
  border-radius: $border-radius;
}

.card-content {
  padding: 1.25rem;
}
</style>
