<template>
  <div :class="$style['card-header-item']" class="d-flex align-items-center">
    <slot />
  </div>
</template>

<style module lang="scss">
.card-header-item {
  &:first-child {
    text-transform: uppercase;
    font-weight: bold;
  }
}
</style>
