<script setup lang="ts">
import { provide, toRef } from 'vue'

const props = withDefaults(
  defineProps<{
    open?: boolean
    headerPlacement?: 'inside' | 'outside'
  }>(),
  {
    open: true,
    headerPlacement: 'inside'
  }
)

provide('card.headerPlacement', toRef(props, 'headerPlacement'))
provide('card.isOpen', toRef(props, 'open'))
</script>

<template>
  <section :class="[$style[`with-header-${headerPlacement}`], $style['card-container']]">
    <slot />
  </section>
</template>

<style module lang="scss">
$border-radius: .625rem;

.with-header-inside {
  box-shadow: .125rem .125rem .25rem rgba(0, 0, 0, 0.16);
  border: .1px solid #d1d1d1;
  background: #fff;
  border-radius: $border-radius;
}
</style>
