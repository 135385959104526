import {
  applyFilteringToUrl,
  applyPagingToUrl,
  applySortingToUrl,
  buildApiUrl,
  sendPostRequest,
  sendPutRequest,
  sendPatchRequest,
  sendPaginationRequest,
  Type,
  sendGetRequest, sendDeleteRequest
} from '@/features/api'
import { format } from 'date-fns/format'
import { DateFormats } from '@/helpers/formatting'
import type { SortedField } from '@/types'
import {
  InvoiceSchema,
  PaginatedInvoiceSchema,
  InvoiceMeterReadingsSchema,
  InvoiceActivitySchema,
  type InvoiceMeterReadings,
  InvoiceConfigSchema,
  CreateBillingChargeSchema,
  UpdateBillingChargeValuesSchema
} from '@/features/billing/schemas'
import { InvoiceStatus } from '@/features/billing/constants'
import { assertSchema } from "@/helpers/assert-schema";
import { z } from "zod";
import { objectWithoutKeys, withoutKeys } from '@/helpers'


export async function approveInvoice(invoiceId: number) {
  const url = buildApiUrl(Type.AssetManagement, `billing/invoices/${invoiceId}/approvals`)

  await sendPutRequest(url)
}


export async function regenerateInvoice(invoiceId: number) {
  const url = buildApiUrl(Type.AssetManagement, `billing/invoices/${invoiceId}/regenerate`)

  // Endpoint always returns `true` and will throw an error if there's an issue.
  await sendPostRequest(url)
}


export async function recreateInvoice(invoiceId: number) {
  const url = buildApiUrl(Type.AssetManagement, `billing/invoices/${invoiceId}/recreate`)
  await sendPostRequest(url)
}


export async function sendInvoice(invoiceId: number) {
  const url = buildApiUrl(Type.AssetManagement, `billing/invoices/${invoiceId}/send`)

  await sendPostRequest(url)
}


export async function cancelInvoice(invoiceId: number) {
  const url = buildApiUrl(Type.AssetManagement, `billing/invoices/${invoiceId}/cancelled`)

  await sendPutRequest(url)
}


export async function getInvoiceActivity(invoiceId: number) {
  const url = buildApiUrl(Type.AssetManagement, `billing/invoices/${invoiceId}/activity`)

  const { data } = await sendGetRequest(url)

  assertSchema(data, z.array(InvoiceActivitySchema))

  return data
}


export async function getPaginatedInvoices(params: Partial<{
  page: number
  pageSize: number
  sort: SortedField[]
  statuses: InvoiceStatus[]
  customerIds: number[]
  startAt: Date
  endAt: Date
  filter: string
}>) {
  const url = buildApiUrl(Type.AssetManagement, 'billing/invoices')

  applyPagingToUrl(url, params.page, params.pageSize)
  applyFilteringToUrl(url, params.filter)
  applySortingToUrl(url, params.sort)

  if (params.customerIds !== undefined && params.customerIds.length > 0) {
    url.searchParams.set('customers', params.customerIds.join(','))
  }

  if (params.startAt !== undefined) {
    url.searchParams.set('start', format(params.startAt, DateFormats.YearMonthDay))
  }

  if (params.endAt !== undefined) {
    url.searchParams.set('end', format(params.endAt, DateFormats.YearMonthDay))
  }

  if (params.filter !== undefined && params.filter.trim().length > 0) {
    url.searchParams.set('filter', params.filter.trim())
  }

  if (params.statuses !== undefined && params.statuses.length > 0) {
    url.searchParams.set('status', params.statuses.map(x => x.valueOf()).join(','))
  }

  const { paging, records } = await sendPaginationRequest(url, PaginatedInvoiceSchema)

  return {
    paging,
    records: records.map(
      x => ({
        ...x,
        previewUrl: buildApiUrl(Type.AssetManagement, `billing/invoices/${x.id}/preview`).toString(),
        downloadUrl: buildApiUrl(Type.AssetManagement, `billing/invoices/${x.id}/files`).toString(),
        solarReportUrl: buildApiUrl(Type.AssetManagement, `billing/invoices/${x.id}/reports/solar`).toString(),
      })
    )
  }
}

export async function getInvoiceConfig(assetCode: number){

  const url = buildApiUrl(Type.AssetManagement, `assets/${assetCode}/billings/invoices/dummies`)

  const { data } = await sendGetRequest(url)

  assertSchema(data, InvoiceConfigSchema)

  return data
}

type billingConfig = {
  id: number,
  showMeterReadings: boolean,
  showSavings: boolean
}

export async function saveInvoiceConfig(assetCode: number, billingConfig : billingConfig){

  const url = buildApiUrl(Type.AssetManagement, `assets/${assetCode}/billing/invoices/billing-configurations/${billingConfig.id}`)

  await sendPatchRequest(url, { data: billingConfig })
}

export async function getInvoice(invoiceId: number){

  const url = buildApiUrl(Type.AssetManagement, `billing/invoices/${invoiceId}?includeCustomer=true`)

  const { data } = await sendGetRequest(url)

  assertSchema(data, InvoiceSchema)

  return data
}

export async function saveCharges(invoiceId: number, invoiceCharges : { id: number, quantity: number, description: string }[]){
    const url = buildApiUrl(Type.AssetManagement, `billing/invoices/${invoiceId}/charges`)

    await sendPatchRequest(url, { data: invoiceCharges })
}

type InvoiceConfig = {
  invoiceId: number,
  showMeterReadings: boolean,
  showSavings: boolean,
  poNumber: string
}
export async function updateInvoiceConfig(invoiceConfig : InvoiceConfig){
  const url = buildApiUrl(Type.AssetManagement, `billing/invoices/${invoiceConfig.invoiceId}/configurations`)
  await sendPatchRequest(url,{ data: invoiceConfig })
}

export async function updateInvoiceMeterReadings(invoiceId: number, invoiceMeterReading: InvoiceMeterReadings[], updatingConfig: boolean){
  const url = buildApiUrl(Type.AssetManagement, `billing/invoices/${invoiceId}/meters?updatingConfig=${updatingConfig}`)
  await sendPutRequest(url, { data: invoiceMeterReading })
}

export async function getInvoiceMeters(invoiceId : number){
  const url = buildApiUrl(Type.AssetManagement, `billing/invoices/${invoiceId}/meters`)
  const { data } = await sendGetRequest(url)

  assertSchema(data, z.array(InvoiceMeterReadingsSchema))
  return data
}

export async function updateRecurringCharge(assetCode: number, charge: z.infer<typeof UpdateBillingChargeValuesSchema>) {

  assertSchema(charge, UpdateBillingChargeValuesSchema)

  const url = buildApiUrl(Type.AssetManagement, `assets/${assetCode}/invoices/charges/${charge.id}`)

  // Remove the [id] property.
  const data = withoutKeys(charge, ['id'])

  await sendPutRequest(url, { data })
}

export async function removeRecurringCharge(assetCode: number, chargeId: number) {
  await sendDeleteRequest(
    buildApiUrl(Type.AssetManagement, `assets/${assetCode}/invoices/charges/${chargeId}`)
  )
}

export async function createRecurringCharge(assetCode: number, charge: z.infer<typeof CreateBillingChargeSchema>) {

  assertSchema(charge, CreateBillingChargeSchema)

  const url = buildApiUrl(Type.AssetManagement, `assets/${assetCode}/invoices/charges`)
  const data = objectWithoutKeys(charge, ['rateInFractionalCurrency']) as Record<string, any>
  data.rateInCents = charge.rateInFractionalCurrency

  await sendPostRequest(url, { data })
}
